<template>
  <main class="pt-1 mb:pt-10 pb-16 sm:pb-10 sm:pt-20 lg:pb-28 flex justify-center">
    <div class="mt-16 space-y-4 sm:mt-10">
      <div class="text-white text-6xl font-bold float-left ml-20 hidden md:flex font-[1100] opacity-50"
        style="margin-top: -19px;font-weight: 900;letter-spacing: 0.3em;">TRABALHOS</div>
      <div class="px-4 sm:px-6 lg:px-8 max-w-7xl">
        <div class="mx-auto backdrop-blur-2xl"
          style="border-radius:50px;box-shadow: rgba(0, 0, 0, 0.64) 0px 10px 50px;">
          <div class="p-14">
            <Menu v-if="filtros" as="div" class="relative float-right w-50 text-left">
              <div class="col-start-2">
                <MenuButton
                  class="inline-flex w-50 rounded-md border border-gray-300 backdrop-blur-3xl px-4 py-2 text-sm font-medium text-gray-100 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  <span class="font-bold">Filtrar por: </span><span class="ml-2">{{ filterOptions(filter) }}</span>
                  <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </MenuButton>
              </div>

              <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute bg-white text-gray-600 right-0 z-10 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div class="py-1 cursor-pointer">
                    <MenuItem v-for="index in filtros" :key="index">
                    <a @click="filtrarMenu(index)"
                      class="block px-4 py-2 text-sm hover:text-gray-400">{{ filterMenu(index) }}</a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <ul class="p-3 md:p-12">
            <div v-if="!loading" class="blur-sm animate-pulse">
              <li v-for="items in 9" :key="items" class="w-full border-b border-b-gray-700">
                <div class="group relative py-6 sm:rounded-2xl">
                  <div class="relative flex">
                    <dl class="ml-4 overflow-hidden ml-6 w-full flex flex-col sm:flex-row">
                      <div class="text-left text-white w-full inline-block">
                        <h2 class="text-white tit text-2xl font-bold flex-none">Lorem ipsum dolor sit amet</h2>
                        <p class="text-purple-600 font-bold text-xs mb-2 mt-1">Publicado 07/12/2022 00:08:28</p>
                        <p class="text-purple-600 font-bold text-xs mb-2 mt-1">Categoria Lorem</p>
                        <p class="cription text-gray-400">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Dolor sed viverra ipsum nunc aliquet bibendum enim facilisis.
                          Ultrices vitae auctor eu augue ut lectus. Ut etiam sit amet nisl purus. Justo laoreet sit amet
                          cursus sit amet dictum. Ultrices dui sapien eget mi proin sed libero enim sed. Sit amet purus
                          gravida quis. Turpis in eu mi bibendum neque egestas. Potenti nullam ac tortor vitae purus
                          faucibus. Neque sodales ut etiam sit amet nisl purus. Risus at ultrices mi tempus imperdiet
                          nulla malesuada pellentesque elit. At tempor commodo ullamcorper a lacus vestibulum. Fermentum
                          leo vel orci porta. Sed adipiscing diam donec adipiscing tristique risus nec.
                        </p>
                      </div>
                      <div class="inline-block w-full">
                        <div class="float-right w-42 my-3">
                          <img class="px-5 m-auto p-2 w-32 grayscale invert"
                            src="https://wkncdn.com/newx/assets/build/img/home/wkn-logo.8133630bc.svg" />
                          <a target="_blank"
                            class="bg-green-400 w-42 text-sm hover:bg-green-500 text-white font-bold py-2 px-7 rounded-full cursor-pointer">
                            FAZER PROPOSTA
                          </a>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </li>
            </div>
            <div v-else>
              <li v-for="index in trabalhos" :key="index" class="w-full border-b border-b-gray-700">
                <div class="group relative py-6 sm:rounded-2xl">
                  <div class="relative flex">
                    <dl class="ml-4 overflow-hidden ml-6 w-full flex flex-col sm:flex-row">
                      <div class="text-left text-white w-full inline-block">
                        <h2 class="text-white tit text-2xl font-bold flex-none">{{ index.title }}</h2>
                        <p class="text-purple-600 font-bold text-xs mb-2 mt-1">Publicado {{ index.date }}</p>
                        <p class="text-purple-600 font-bold text-xs mb-2 mt-1">Categoria {{
                            filterMenu(index.categorie).toUpperCase()
                        }}</p>
                        <p class="cription text-gray-400">
                          {{ index.description.replace(/Categoría: Redacción y Traducción.*/, '').replace(/Subcategoría: Revisión de textos.* /,'').replace(/Categoría: Diseño y Multimedia.* /,'') }}
                        </p>
                      </div>
                      <div class="inline-block w-full">
                        <div class="float-right w-42 my-3">
                          <img v-if="index.site == 'workana'" class="w-32 px-5 m-auto p-2 grayscale invert"
                            src="https://wkncdn.com/newx/assets/build/img/home/wkn-logo.8133630bc.svg" />
                          <img v-else-if="index.site == 'freelancer'" class="w-32 px-2 m-auto p-2 grayscale invert mb-1"
                            src="https://freelancer.com.br/images/logo_br/main-logo.svg" />
                          <img v-else-if="index.site == '99freelas'" class="w-32 px-6 m-auto p-2 grayscale invert mb-1"
                            src="https://theme.zdassets.com/theme_assets/593110/b36667d32ff1146ea605caf6332f792ff01ea376.png" />
                          <a v-if="index.link" :href="index.link" target="_blank"
                            class="bg-green-400 w-42 text-sm hover:bg-green-500 text-white font-bold py-2 px-7 rounded-full cursor-pointer">
                            FAZER PROPOSTA
                          </a>
                          <a v-else href="https://maisconectabrj.online/prscadi/" target="_blank"
                            class="bg-green-400 opacity-50 w-42 text-sm hover:bg-green-500 text-white font-bold py-2 px-7 rounded-full cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg"
                              style="position:fixed;margin-top:-22px; margin-left: -25px" viewBox="0 0 24 24"
                              fill="currentColor" class="w-5 h-5">
                              <path fill-rule="evenodd"
                                d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                                clip-rule="evenodd" />
                            </svg>


                            ASSINE PARA ACESSAR
                          </a>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </li>
            </div>
          </ul>
          <div class="flex flex-col items-center">
            <!-- Help text -->
            <span class="text-sm text-gray-700 dark:text-gray-400">
              Exibindo a página <span class="font-semibold text-gray-900 dark:text-white">{{ currentPage }}</span> de um
              total de <span class="font-semibold text-gray-900 dark:text-white">{{ page }}</span>
            </span>
            <!-- Buttons -->
            <div class="inline-flex mt-2 xs:mt-0 mb-10">
              <button @click="lastPage" v-if="(currentPage != 1)"
                class="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-l hover:bg-purple-500">
                Anterior
              </button>
              <button @click="nextPage" v-if="(currentPage < page)"
                class="px-4 py-2 text-sm font-medium text-white bg-purple-600 border-0 border-l border-gray-700 rounded-r hover:bg-purple-500">
                Próximo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'


export default {
  name: 'ProjectsPage',
  components: {
    Menu, MenuButton, MenuItem, MenuItems, ChevronDownIcon
  },
  data() {
    return {
      trabalhos: [],
      currentPage: 1,
      page: null,
      loading: false,
      filter: "escrita",
      filtros: null
    }
  },
  mounted() {
    this.$socket.on('getProjetos', data => {
      this.page = data.data.totalPages
      this.trabalhos = data.data.docs
      this.filtros = data.filtro
      this.loading = true
    })

    this.sendProjetos()
  },
  methods: {
    filterMenu(index) {
      if (index == 'escrita') {
        return 'Profissional de Digitação'
      } else if (index == 'design') {
        return 'Profissional de Redes Sociais'
      } else if (index == 'traducao') {
        return 'Profissional de Tradução'
      } else if (index == 'marketing') {
        return 'Profissional de Anúncios Online'
      } else if (index == 'assistente') {
        return 'Assistente Virtual'
      } else if (index == 'atendimento') {
        return 'Profissional de Atendimento ao Cliente'
      } else if (index == 'telemarketing') {
        return 'Profissional de Telemarketing'
      } else if (index == 'locucao') {
        return 'Profissional de Locução'
      } else {
        return index
      }
    },
    sendProjetos() {
      this.loading = false
      console.log(this.filter)
      this.$socket.emit('sendProjetos', { default: this.currentPage, filter: this.filter, email: location.href.match(/cuser_email=(.*)/).length > 0 ? location.href.match(/cuser_email=(.*)/)[1].split('&')[0].replace('%40', '@') : null })
    },

    filterOptions(index) {
      if (index == 'escrita') {
        return 'Profissional de Digitação'
      } else if (index == 'design') {
        return 'Profissional de Redes Sociais'
      } else if (index == 'traducao') {
        return 'Profissional de Tradução'
      } else if (index == 'marketing') {
        return 'Profissional de Anúncios Online'
      } else if (index == 'assistente') {
        return 'Assistente Virtual'
      } else if (index == 'atendimento') {
        return 'Profissional de Atendimento ao Cliente'
      } else if (index == 'telemarketing') {
        return 'Profissional de Telemarketing'
      } else if (index == 'locucao') {
        return 'Profissional de Locução'
      } else {
        return index
      }
    },

    filtrarMenu(menu) {
      this.filter = menu
      this.currentPage = 1
      this.sendProjetos()
    },

    nextPage() {
      this.currentPage += 1
      window.scrollTo(0, 0)
      this.sendProjetos()
    },
    lastPage() {
      this.currentPage -= 1
      window.scrollTo(0, 0)
      this.sendProjetos()
    },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

.cription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 67px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 60px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
